<template>
  <!-- <v-container fluid fill-height class="pa-0"> -->
  <LayoutBasic :showLinksMenu="false">
    <!-- <template v-slot:breadcrumbs>
        <v-breadcrumbs class="pa-0 pl-5":items="navigation" divider=">">
          <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">{{ item.text }}</v-breadcrumbs-item>
        </v-breadcrumbs>
      </template> -->
    <template v-slot:navigation-title> Configuraciones </template>
    <template v-slot:navigation-content>
      <v-list-item @click="checkLocalidades()">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Check Localidades</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'condicionesIva' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Condiciones IVA</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'estados' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Estados</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'formasDePago' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Formas de pago</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="$router.push({ name: 'tipoNotificaciones' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Tipo de notificaciones</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
     
     
      <v-list-item @click="$router.push({ name: 'plazoVencimiento' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Plazo de vencimiento</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'motivoCambio' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Motivo de cambio</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'unidadMedida' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Unidad de medida</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'recargo' })">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Recargo</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
     
    </template>
    <template v-slot:navigation-controller> </template>
    <template v-slot:vista-previa>
      <v-row>
        <v-col cols="8">
          <router-view></router-view>
        </v-col>
      </v-row>
    </template>
  </LayoutBasic>
</template>

<script>
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "empresa.registrar",
  components: {
    LayoutBasic,
  },
  data() {
    return {};
  },

  methods: {
    checkLocalidades() {
      this.axios
        .get("/empresas/ubicaciones")
        .then((response) => {
          var data = response.data;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: `${data}`,
            icon: "mdi-check",
            timeout: 3000,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: `${e}`,
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
